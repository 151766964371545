import axios from "axios";
import { config } from "../Utils/baseUrl";
import { BlogPostType } from "../types/BlogPostType";
const baseUrl = `${config.url.API_URL}/Blog`;

export async function createBlogPostAsync(blogPost: BlogPostType) {
    return await axios.post(`${baseUrl}`, blogPost, { withCredentials: true })
}
export async function UpdateLikeStatusAsync(userId: number, blogId: string) {
    return await axios.patch(`${baseUrl}/like`, {userId: userId, blogId: blogId}, { withCredentials: true })
}
export async function getBlogPost(blogPostId: string, userId: number): Promise<BlogPostType> {
    return await axios.get(`${baseUrl}?blogPostId=${blogPostId}&userId=${userId}`, { withCredentials: true }).then(resp => resp.data)
}
export async function getAllBlogPostSnippets(page: number, amount: number, userId: number): Promise<BlogPostType[]> {
    return await axios.get(`${baseUrl}/snippets?page=${page}&amount=${amount}&userId=${userId}`, {withCredentials: true }).then(resp => resp.data)
}
export async function deleteBlogPost(blogPostId: string): Promise<boolean> {
    return await axios.delete(`${baseUrl}?blogPostId=${blogPostId}`, {withCredentials: true }).then(resp => resp.data)
}