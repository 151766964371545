import React, { useContext, useState } from "react";
import { MDXProvider } from "@mdx-js/react";
import ReactMarkdown from "react-markdown";
import { EditorView } from "@codemirror/view";
import { EditorState } from "@codemirror/state";
import { basicSetup } from "codemirror";
import { useCodeMirror } from "@uiw/react-codemirror";
import CurrentUserContext from "../client/UserContextProvider";
import { BlogPostType } from "../types/BlogPostType";
import { createBlogPostAsync } from "../services/blogService";
import { Alert, Button, Form } from "react-bootstrap";
import Style from "../css/blog/blogCreate.module.css"

export default function BlogCreate() {
    const [heading, setHeading] = useState<string>("");
    const [success, setSuccess] = useState<boolean>();
    const [fail, setFail] = useState<boolean>();
    const [postBody, setPostBody] = useState<string>("# Welcome to MDX Editor\n\nThis is **Markdown with JSX** support! 🚀");
    const context = useContext(CurrentUserContext);
    const { currentUser } = context!
    const handleSubmit = async () => {
        const blogPost: BlogPostType = {
            heading: heading,
            body: postBody,
            createdDate: new Date().toLocaleString(),
            creator: null,
            creatorId: currentUser?.id!,
            likesCount: 0,
            currentUserLiked: false
        };
        try {
            await createBlogPostAsync(blogPost)
            setSuccess(true)
            setTimeout(() => setSuccess(false), 3000)
        } catch {
            setFail(true)
            setTimeout(() => setFail(false), 3000)
        }
    };
    const handleChange = (newValue: string) => {
        console.log(newValue)
        setPostBody(newValue);
    };

    return (
        <div id={Style.blogCreateWrapper}>
            {success && <Alert variant="success">Blog successfully created</Alert>}
            {fail && <Alert variant="danger">Blog creation failed</Alert>}
            <h1>Heading:</h1>
            <Form>
                <Form.Control onChange={(e) => setHeading(e.target.value)} />
            </Form>
            <br/>
            <CodeMirrorEditor value={postBody} onChange={handleChange} />
            <Button id={Style.submitButton} onClick={handleSubmit}>Submit</Button>
            <hr className="my-4" />

            <div id={Style.blogOutput}>
                <MDXProvider>
                    <ReactMarkdown >{postBody}</ReactMarkdown>
                </MDXProvider>
            </div>
        </div>
    );
}

// CodeMirror Editor Component
const CodeMirrorEditor = ({ value, onChange }: { value: string; onChange: (val: string) => void }) => {
    const editorRef = React.useRef<HTMLDivElement>(null);
    useCodeMirror({
        container: editorRef.current,
        value,
        extensions: [basicSetup, EditorView.lineWrapping, EditorState.readOnly.of(false)],
        onChange,
    });

    return <div ref={editorRef} id={Style.editor} />;
};
