import React, { useEffect, useState, ComponentType, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import CurrentUserContext from '../client/UserContextProvider';
import CustomLoadingSpinner from '../components/shared/CustomLoadingSpinner';
import { UserRoleType } from '../types/UserRoleType';

interface PrivateRouteProps {
  element: ComponentType<any>;
}

const AdminRoute: React.FC<PrivateRouteProps> = ({ element: Component, ...rest }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const context = useContext(CurrentUserContext);
  const { currentUser } = context!;
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null)

  useEffect(() => {
    const checkStatus = async () => {
      setLoading(true);
      
      if (currentUser) {
        if (currentUser.userRole == UserRoleType.Admin) {
          console.log(currentUser)
          setIsAdmin(true)
        } else {
          setIsAdmin(false)
        }
      } else {
        setTimeout(() => {
          checkStatus()
        }, 2000)
      }
      setLoading(false);
    };

    checkStatus();
  }, [currentUser]);

  if (loading || isAdmin == null) {
    return <CustomLoadingSpinner />;
  }

  if (!isAdmin) {
    return <Navigate to="/home" />;
  }

  return <Component {...rest} />;
};

export default AdminRoute;
