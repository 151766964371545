import { useContext, useEffect, useState } from "react"
import Style from "../css/blog/index.module.css"
import { BlogPostType } from "../types/BlogPostType"
import { deleteBlogPost, getAllBlogPostSnippets } from "../services/blogService"
import DefaultProfilePicture from "../images/default-profile-pic.png"
import { MDXProvider } from "@mdx-js/react"
import ReactMarkdown from "react-markdown"
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import CurrentUserContext from "../client/UserContextProvider"
import { Button } from "react-bootstrap"
import { UserRoleType } from "../types/UserRoleType"
export default function BlogHomePage() {
    var [blogs, setBlogs] = useState<BlogPostType[]>()
    const context = useContext(CurrentUserContext);
    const { currentUser } = context!
    useEffect(() => {
        const retrieveBlogPosts = async () => {
            setBlogs(await getAllBlogPostSnippets(0, 10, currentUser?.id ? currentUser.id : 0))
        }
        retrieveBlogPosts()
    }, [currentUser])

    const deleteBlog = async (blogPostId: string) => {
        await deleteBlogPost(blogPostId);
    }
    const displayBlogs = () => {
        if (blogs != null && blogs?.length > 0) {
            return blogs?.map((blog) => {
                return <div>
                    <a href={`/blog/${makeUrlFriendly(blog.heading)}-${blog.id}`} >
                    <div className={Style.blogSnippet}>
                        <h1 className={Style.blogPostHeading}>{blog.heading}</h1>
                        <hr style={{ width: "30%" }} />
                        <div className={Style.blogSnippetBody}>
                            <MDXProvider>
                                <ReactMarkdown>{blog.body}</ReactMarkdown>
                            </MDXProvider>
                        </div>
                        <div className={Style.postMetaDataWrapper}>
                            <div className={Style.postMetaData}>
                                <div className={Style.postMetaDataTopLayer}>
                                    <p className={Style.blogCreator}>{blog.creator?.firstName} {blog.creator?.lastName}</p>
                                    <p className={Style.blogCreatedDate}>{new Date(blog.createdDate).toLocaleDateString()}</p>
                                </div>
                                <img className={Style.profilePhoto} src={blog.creator?.profilePicture ? "data:image/png;base64," + blog.creator?.profilePicture : DefaultProfilePicture} alt="User profile" />
                            </div>
                        </div>
                        <p>{blog.currentUserLiked ? <ThumbUpAltIcon /> : <ThumbUpOffAltIcon />} - {blog.likesCount} likes so far</p>
                    </div></a>
                    {currentUser?.userRole == UserRoleType.Admin && <Button className={Style.deleteButton} onClick={async () => await deleteBlog(blog.id!)}>Delete</Button>}
                </div>
            })
        }
        return []

    }

    const makeUrlFriendly = (str: string) => {
        return str
            .toLowerCase()
            .replace(/\s+/g, '-')
            .replace(/[^\w\-]+/g, '')
            .replace(/\-\-+/g, '-')
            .replace(/^-+/, '')
            .replace(/-+$/, '');
    };
    return (
        <div id={Style.blogWrapper}>
            <h1 id={Style.blogPageHeading}>BMI Blog</h1>
            <div >{displayBlogs()}</div>
        </div>
    )
}