import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { getBlogPost, UpdateLikeStatusAsync } from '../services/blogService';
import { BlogPostType } from '../types/BlogPostType';
import { MDXProvider } from '@mdx-js/react';
import DefaultProfilePicture from "../images/default-profile-pic.png"
import ReactMarkdown from 'react-markdown';
import Style from "../css/blog/blogPost.module.css";
import CurrentUserContext from '../client/UserContextProvider';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';

const Blog: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const regex = /([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})$/;
    const context = useContext(CurrentUserContext);
    const { currentUser } = context!
    const [blog, setBlog] = useState<BlogPostType>();
    const [blogId, setBlogId] = useState<string>()
    const [updated, setUpdated] = useState(false)
    useEffect(() => {
        const fetchBlogPost = async () => {
            if (id) {
                const match = id.match(regex);
                if (match) {
                    setBlogId(match[1].toString())
                    const response = await getBlogPost(match[1].toString(), currentUser?.id ? currentUser?.id : 0);
                    setBlog(response);
                } else {
                    window.location.href = "/blog"
                }
            }
        };

        if (id) {
            fetchBlogPost();
        }
    }, [id, updated, currentUser]);

    if (!blog) {
        return <div>Loading...</div>;
    }
    const like = async () => {
        if (currentUser == null)  {
            window.location.href = "/login"
        }
        else if (blogId) {
            await UpdateLikeStatusAsync(currentUser!.id!, blogId)
            setUpdated(!updated)
        }
    }
    return (
        <div id={Style.blogPostWrapper}>
            <h1>{blog.heading}</h1>
            <div className={Style.postMetaDataWrapper}>
                <div className={Style.postMetaData}>
                    <img className={Style.profilePhoto} src={blog.creator?.profilePicture ? "data:image/png;base64," + blog.creator?.profilePicture : DefaultProfilePicture} alt="User profile" />
                    <div className={Style.postMetaDataTopLayer}>
                        <p className={Style.blogCreator}>{`${blog.creator?.firstName} ${blog.creator?.lastName}`} </p>
                        <p className={Style.blogCreatedDate}>{new Date(blog.createdDate).toLocaleDateString()}</p>
                    </div>
                </div>
            </div>
            <hr />
            <p>{blog.currentUserLiked ? <span className={Style.likeButton} onClick={like}><ThumbUpAltIcon/> unlike</span> : <span className={Style.likeButton} onClick={like}><ThumbUpOffAltIcon/> like</span>} - {blog.likesCount} likes so far</p>
            <MDXProvider>
                <ReactMarkdown>{blog.body}</ReactMarkdown>
            </MDXProvider>
        </div>
    );
};

export default Blog;
