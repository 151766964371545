import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import Styles from "../css/landing/altLandingPage.module.css"
import WorkStockImage from "../images/workStockImage2.png"
import BarStockImage from "../images/barStockImage3.png"
import Step1 from "../images/Step1.png"
import Step2 from "../images/Step2.png"
import Step3 from "../images/Step3.png"
import "./../css/theme.css"
import landingPageTextObject, { LandingPageVariationType } from "../Text/landingPageAlt"
import { useEffect, useState } from "react";

export default function LandingPage() {
    const [isCustomer, setIsCustomer] = useState(true)
    const [isHeaderImageLoading, setIsHeaderImageLoading] = useState(true)
    const [isCtaImageLoading, setIsCtaImageLoading] = useState(true)
    const [landingPageText, setLandingPageText] = useState<LandingPageVariationType>()
    useEffect(() => {
        setLandingPageText(isCustomer ? landingPageTextObject.customer : landingPageTextObject.developer)
    }, [isCustomer])
    const returnSalePoint = (salePoint: number) => {
        return <>
            <div className={Styles.salePointWrapper}>

                <div className={Styles.salePointWrapperDiv}>
                    <p className={Styles.salePoint}>{landingPageText?.sellPoint[salePoint].point}</p>
                </div>
                <div className={Styles.salePointLinkLine} />
                <p className={Styles.salePointDesc}>{landingPageText?.sellPoint[salePoint].desc}</p>
            </div>

        </>

    }
    return (
        <>
            <Row>
                <Col>
                    <div id={Styles.altLandingPage}>
                        <div id={Styles.headerImageWrapper}>
                            <div style={{display:  !isHeaderImageLoading ? "none" : "flex", width: "100%", height: "40vw", justifyContent: "center", alignItems: "center" }} ><Spinner /></div>
                            <img id={Styles.headerImage} style={{display:  isHeaderImageLoading ? "none" : "inline-block"}} onLoad={() => setIsHeaderImageLoading(false)} className="mb-3" src={WorkStockImage} alt="Build My Idea Logo" />
                            <div id={Styles.headerCTA}>
                                <p id={Styles.IAmA}>I am a...</p>
                                <small id={Styles.selectOne}>(select one)</small>
                                <div id={Styles.RoleButtons}>
                                    <Button className={Styles.roleButton} style={{ backgroundColor: isCustomer ? "var(--colour5)" : "grey" }} onClick={() => setIsCustomer(true)}>Customer</Button>
                                    <Button className={Styles.roleButton} style={{ backgroundColor: isCustomer ? "grey" : "var(--colour4)" }} onClick={() => setIsCustomer(false)}>Developer</Button>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div id={Styles.tagLineWrapper}>
                            <p id={Styles.tagLine} >{landingPageText?.tagLine}</p>
                        </div>

                        {returnSalePoint(0)}
                        <div className={Styles.salePointWrapper}>

                            <p className={Styles.salePointDesc}>{landingPageText?.sellPoint[1].desc}</p>
                            <div className={Styles.salePointLinkLine} />
                            <div className={Styles.salePointWrapperDiv}>
                                <p className={Styles.salePoint}>{landingPageText?.sellPoint[1].point}</p>
                            </div>
                        </div>
                        {returnSalePoint(2)}
                        <p id={Styles.CTAHeading}>Heard Enough?</p>
                        <p id={Styles.CTAWrapper}>
                            <a href="/login" className={Styles.CTAButton}>Login</a>
                            <p style={{ color: "white", fontSize: "3vw", height: "100%", cursor: "default" }}>or</p>
                            <a href="/sign-up" className={Styles.CTAButton}>Signup</a>
                        </p>
                        <div id={Styles.CTAImageWrapper}>
                        <div style={{display:  !isCtaImageLoading ? "none" : "flex", width: "100%", height: "40vw", justifyContent: "center", alignItems: "center" }} ><Spinner /></div>
                            <img id={Styles.CTAImage} style={{display:  isCtaImageLoading ? "none" : "inline-block"}} onLoad={() => setIsCtaImageLoading(false)} className="mb-3" src={BarStockImage} alt="Build My Idea Logo" />
                        </div>
                        <div id={Styles.StepsWrapper}>
                            <div className={Styles.Step}>
                                <img className={Styles.StepImages} src={Step1}></img>
                                <p className={Styles.StepHeading} >{landingPageText?.steps.step1.heading}</p>
                                <div className={Styles.StepsBorder} style={{height: "20vh"}}/>
                                <p className={Styles.StepValue} >{landingPageText?.steps.step1.value}</p>
                            </div>
                            <div className={Styles.Step}>
                                <img className={Styles.StepImages} src={Step2}></img>
                                <p className={Styles.StepHeading} >{landingPageText?.steps.step2.heading}</p>
                                <div className={Styles.StepsBorder} style={{height: "60vh"}}/>
                                <p className={Styles.StepValue}>{landingPageText?.steps.step2.value}</p>
                            </div>
                            <div className={Styles.Step}>
                                <img className={Styles.StepImages} src={Step3}></img>
                                <p className={Styles.StepHeading} >{landingPageText?.steps.step3.heading}</p>
                                <div className={Styles.StepsBorder} style={{height: "90vh"}}/>
                                <p className={Styles.StepValue}>{landingPageText?.steps.step3.value}</p>
                            </div>
                        </div>
                        <p id={Styles.landingPageFinePrint}>{landingPageText?.finePrint}</p>
                    </div>
                </Col>
            </Row >

        </>
    )
}