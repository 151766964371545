import { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/globals.css';
import { ThemeProvider } from '@mui/material/styles';
import Menu from './components/shared/menu';
import Footer from './components/shared/Footer';
import { RetrieveCurrentUserInfo } from './services/userService';
import CurrentUserContext from './client/UserContextProvider';
import { UserType } from './types/UserType';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PrimeReactProvider } from 'primereact/api';
import { config } from './Utils/baseUrl';
import LoginPage from './pages/login';
import SignUpDetailsPage from './pages/signUpDetails'
import { createTheme } from '@mui/material/styles';
import {
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Profile from './pages/profile';
import Dashboard from './pages/dashboard';
import Submissions from './pages/manageSubmissions';
import Requests from './pages/manageRequests';
import Settings from './pages/settings';
import Job from './pages/job';
import NotFound from './pages/notFound';
import PrivateRoute from './Utils/privateRoute'
import SignUpPage from './pages/signUp';
import ActivateAccount from './pages/activateAccount';
import Admin from './pages/admin';
import TermsAndConditions from './pages/termsAndConditions';
import FaqPage from './pages/faq';
import Contact from './pages/contact';
import ForgotPassword from './pages/forgotPassword';
import TermsAndConditionsAccept from './pages/termsAndConditionsAccept';
import AltUserProfilePageWrapper from './pages/altProfile';
import ReactGA from 'react-ga4';
import PrivacyPolicy from './pages/privacyPolicy';
import ReturnPolicy from './pages/returnPolicy';
import AltLandingPage from './pages/landingPage';
import "./css/theme.css"
import Home from './pages/home';
import LandingPage from './pages/landingPage';
import BlogHomePage from './pages/blogHomePage';
import BlogCreate from './pages/blogCreate';
import AdminRoute from './Utils/adminRoute';
import Blog from './pages/blog';

const theme = createTheme({
  components: {
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottom: '2px solid #999',
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: '2px solid #999',
          },
        },
      },
    },
  },
  palette: {
    text: {
      primary: "#000000",
      secondary: "#999",
    },
    primary: {
      main: '#ffffff',
    },
  },
});
function App() {
  const [currentUser, setCurrentUser] = useState<UserType>();
  const clearCurrentUser = () => setCurrentUser(undefined);
  useEffect(() => {
    ReactGA.initialize('G-N95HMQVXE7');
    const fetchUserInfo = async () => {
      try {
        const response: UserType = await RetrieveCurrentUserInfo();
        setCurrentUser(response);
      } catch (error) {
        console.error("Failed to fetch user info:", error);
      }
    };
    fetchUserInfo();
  }, []);

  return (
    <div className="App">
      <Router>
        <ThemeProvider theme={theme}>
          <PrimeReactProvider>
            <GoogleOAuthProvider clientId={config.auth.GOOGLE_OAUTH_CLIENT_ID}>
              <CurrentUserContext.Provider value={{ currentUser, clearCurrentUser }}>
                <div id="menu">
                  <Menu setCurrentUser={setCurrentUser} />
                </div>
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="*" element={<NotFound />} />
                  <Route path="/sign-up" element={<SignUpPage />} />
                  <Route path="/sign-up/activate-account" element={<ActivateAccount />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/faq" element={<FaqPage />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/policy/terms-and-conditions" element={<TermsAndConditions />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/profile" element={<PrivateRoute element={Profile} />} />
                  <Route path="/profile/:username" element={<AltUserProfilePageWrapper />} />
                  <Route path="/profile/dashboard" element={<PrivateRoute element={Dashboard} />} />
                  <Route path="/profile/dashboard/submissions" element={<PrivateRoute element={Submissions} />} />
                  <Route path="/profile/dashboard/requests" element={<PrivateRoute element={Requests} />} />
                  <Route path="/settings" element={<PrivateRoute element={Settings} />} />
                  <Route path="/job/:id" element={<Job />} />
                  <Route path="/blog/:id" element={<Blog />} />
                  <Route path="/blog" element={<BlogHomePage />} />
                  <Route path="/blog/create" element={<AdminRoute element={BlogCreate} />} />
                  <Route path="/sign-up/details" element={<PrivateRoute element={SignUpDetailsPage} />} />
                  <Route path="/admin" element={<AdminRoute element={Admin} />} />
                  <Route path="/policy/terms-and-conditions-accept" element={<PrivateRoute element={TermsAndConditionsAccept} />} />
                  <Route path="/policy/privacy" element={<PrivacyPolicy />} />
                  <Route path="/policy/return" element={<ReturnPolicy />} />
                </Routes>
                <Footer />
              </CurrentUserContext.Provider>
            </GoogleOAuthProvider>
          </PrimeReactProvider>
        </ThemeProvider>
      </Router>
    </div >
  );
}

export default App;
